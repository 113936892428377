import { z } from 'zod'
import { MarketingMethodsEnum, UtilitiesEnum } from '~services/listings'
import dayjs, { Dayjs } from 'dayjs'
import { refinePosNumber, refinePosNumberMessage } from '~utils/schemaUtils'

const stepTwoSchema = z
  .object({
    description: z.string().nonempty('Dieses Feld muss ausgefüllt werden.'),
    numRooms: z.string().optional().refine(refinePosNumber, refinePosNumberMessage),
    floor: z.string().optional().refine(refinePosNumber, refinePosNumberMessage),
    numFloors: z.string().optional().refine(refinePosNumber, refinePosNumberMessage),
    utilities: z.nativeEnum(UtilitiesEnum).array().optional(),
    constructionYear: z.string().optional().refine(refinePosNumber, refinePosNumberMessage),
    renovationYear: z.string().optional().refine(refinePosNumber, refinePosNumberMessage),
    monumentStatus: z.string().optional(),
    hasBasement: z.string().optional(),
    hasAttic: z.string().optional(),
    furnished: z.string().optional(),
    vacantSince: z.instanceof(dayjs as unknown as typeof Dayjs).optional(),
    prevMarketingMethods: z.nativeEnum(MarketingMethodsEnum).optional(),
    mainRemarketingProblems: z
      .string()
      .max(800, 'Die Beschreibung darf maximal 800 Zeichen lang sein.')
      .optional(),
    investmentDetails: z
      .string()
      .max(800, 'Die Beschreibung darf maximal 800 Zeichen lang sein.')
      .optional(),
    reqImprovementsCommercialUse: z
      .string()
      .max(800, 'Die Beschreibung darf maximal 800 Zeichen lang sein.')
      .optional(),
    neighbourCooperationDetails: z
      .string()
      .max(800, 'Die Beschreibung darf maximal 800 Zeichen lang sein.')
      .optional()
  })
  .refine(
    data =>
      !Number(data.constructionYear)
        ? true
        : Number(data.constructionYear) >= 1000 &&
          Number(data.constructionYear) <= Number(new Date().getFullYear()),
    {
      message:
        'Bitte geben Sie ein gültiges Jahr an. Das Baujahr kann sich nicht in der Zukunft befinden.',
      path: ['constructionYear']
    }
  )
  .refine(
    data =>
      !Number(data.renovationYear)
        ? true
        : Number(data.renovationYear) >= 1000 &&
          Number(data.renovationYear) <= Number(new Date().getFullYear()) &&
          Number(data.constructionYear)
        ? Number(data.renovationYear) >= Number(data.constructionYear)
        : true,
    {
      message:
        'Bitte geben Sie ein gültiges Jahr an. Das Sanierungsjahr muss nach dem Baujahr liegen und kann sich nicht in der Zukunft befinden.',
      path: ['renovationYear']
    }
  )

type StepTwoSchemaType = z.infer<typeof stepTwoSchema>

export { stepTwoSchema, type StepTwoSchemaType }
