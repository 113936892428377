import { Typography, Box } from '@mui/material'
import { InfoBoxProps } from './types'

export default function InfoBox({ title, noMarginTop, children }: InfoBoxProps) {
  return (
    <Box p={3} mt={noMarginTop ? 0 : 3} mb={3} bgcolor="rgba(217, 217, 217, 0.2)" gap={3}>
      {title && (
        <Typography variant="h5" mb={1}>
          {title}
        </Typography>
      )}
      {children}
    </Box>
  )
}
