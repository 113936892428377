import { Box, Container, Typography } from '@mui/material'
import Image from 'mui-image'

import headerLogo2 from '~assets/images/header-logo-194x194.png'

import { Navbar } from '.'

export const Header = () => (
  <Container
    component="header"
    sx={{ display: 'flex', gap: 8, paddingTop: 0, paddingBottom: '2rem' }}
  >
    <Box
      sx={{
        width: '194px',
        height: '194px',
        flexBasis: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Image src={headerLogo2} width="164px" height="auto" fit="contain" />
    </Box>

    <Box sx={{ width: '100%' }}>
      <Typography
        variant="h1"
        sx={{ textTransform: 'capitalize', marginTop: '5.5rem', marginBottom: '-0.5rem' }}
        color="secondary"
      >
        Traunreut. Digital.
      </Typography>

      <Navbar />
    </Box>
  </Container>
)
