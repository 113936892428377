import { Box } from '@mui/material'

import { PropsWithChildren } from 'react'

import { Header } from '~components/header'
import { Footer } from '~components/footer'

// helper component to wrap page components with a standardised page layout with navigation toolbar and account menu
export const PageScaffold = ({ children }: PropsWithChildren) => (
  <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
    <Header />

    <Box component="main">{children}</Box>

    <Footer />
  </Box>
)
