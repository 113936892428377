import { Box, Container } from '@mui/material'
import { Outlet } from 'react-router-dom'

import { ProfileNavigation } from '~components/profileNavigation'

export const Profile = () => (
  <Container sx={{ display: 'flex', gap: 4 }}>
    <ProfileNavigation />

    <Box sx={{ flexGrow: 1 }}>
      {/*----- This is where the sub-route components are being rendered -----*/}
      <Outlet />
    </Box>
  </Container>
)
