import { Box, Typography } from '@mui/material'

import { MapSwitchProps } from '.'
import { FormatListBulleted as FormatListBulletedIcon, Map as MapIcon } from '@mui/icons-material'

/**
 * A switch component for switching between the map and the list view
 */
export const MapSwitch = ({ selected, switchView }: MapSwitchProps) => {
  const commonStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    width: '100%',
    height: '100%',
    border: '1px solid',
    borderColor: 'neutral.light'
  }

  const viewButton = {
    display: 'flex',
    padding: '.5rem',
    alignItems: 'center'
  }

  const viewLabel = {
    marginTop: '-3px',
    marginLeft: '5px'
  }

  return (
    <Box
      id="mapSwitch"
      sx={{
        display: 'flex',
        borderRadius: '.75rem',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        justifyContent: 'center',
        alignItems: 'center',
        height: '40px'
      }}
    >
      <Box
        sx={{
          ...commonStyles,
          borderRadius: '.75rem 0 0 .75rem',
          backgroundColor: 'white',
          ...(!selected ? { backgroundColor: 'primary.main' } : '')
        }}
      >
        <Box onClick={switchView} sx={viewButton}>
          <MapIcon />
          <Typography sx={viewLabel}>Karte</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          ...commonStyles,
          borderRadius: '0 .75rem .75rem 0',
          backgroundColor: 'white',
          borderLeft: 'none',
          ...(selected ? { backgroundColor: 'primary.main' } : '')
        }}
      >
        <Box onClick={switchView} sx={viewButton}>
          <FormatListBulletedIcon />
          <Typography sx={viewLabel}>Liste</Typography>
        </Box>
      </Box>
    </Box>
  )
}
