export enum OrientationEnum {
  horizontal = 'horizontal',
  vertical = 'vertical'
}

export type Pagination = {
  limit: number
  offset: number
  previous: boolean
}

export type PaginatedResponse<T> = {
  next: string | null
  offset_next: number | null
  previous: string | null
  offset_previous: number | null
  total: number
  items: T
}
