import { z } from 'zod'

import { GeneralListingUseEnum, ListingTypesEnum } from '~services/listings'
import { VoucherTypesEnum } from '~services/vouchers'
import { refinePosNumberMessage, refinePosNumber } from '~utils/schemaUtils'

const filteringSchema = z.object({
  min_usable_area: z.string().optional().refine(refinePosNumber, refinePosNumberMessage),
  max_usable_area: z.string().optional().refine(refinePosNumber, refinePosNumberMessage),
  min_price: z.string().optional().refine(refinePosNumber, refinePosNumberMessage),
  max_price: z.string().optional().refine(refinePosNumber, refinePosNumberMessage),
  listing_type: z.nativeEnum(ListingTypesEnum),
  general_listing_use: z.nativeEnum(GeneralListingUseEnum),
  gutscheine: z.nativeEnum(VoucherTypesEnum)
})

type FilteringSchemaType = z.infer<typeof filteringSchema>

export { filteringSchema, type FilteringSchemaType }
