import GpsNotFixedIcon from '@mui/icons-material/GpsNotFixed'
import { Box } from '@mui/material'

import { MapHomeBtnProps } from '~components/mapButtons'

export const MapHomeButton = ({ centerMap }: MapHomeBtnProps) => (
  <Box
    sx={{
      width: '32px',
      height: '32px',
      cursor: 'pointer',
      backgroundColor: 'white',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '.4rem'
    }}
    onClick={centerMap}
  >
    <GpsNotFixedIcon />
  </Box>
)
