import { useLocation, Navigate } from 'react-router-dom'
import { useAuthenticator } from '@aws-amplify/ui-react'

import { RequireAuthProps } from '.'

export const RequireAuth = ({ Fallback, children }: RequireAuthProps) => {
  const location = useLocation()
  const { route } = useAuthenticator(context => [context.route])

  if (route !== 'authenticated') {
    return Fallback ? <Fallback /> : <Navigate to="/login" state={{ from: location }} replace />
  }

  return <>{children}</>
}
