import { Box } from '@mui/material'
import FmdGoodIcon from '@mui/icons-material/FmdGood'

import { POIToggleButtonProps } from '.'

export const POIToggleButton = ({ showPOIs }: POIToggleButtonProps) => (
  <Box
    sx={{
      width: '32px',
      height: '32px',
      cursor: 'pointer',
      backgroundColor: 'white',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '.4rem'
    }}
    onClick={showPOIs}
  >
    <FmdGoodIcon />
  </Box>
)
