import useSWR from 'swr'

import { fetcher } from '~services/utils'
import { ListingDetails } from '~services/listings'

export const useListing = (id: number | null) => {
  const { data, error, isLoading } = useSWR<ListingDetails>(id ? `listings/${id}` : null, url =>
    fetcher(url, 'GET')
  )

  return {
    listing: data,
    error,
    isLoading
  }
}
