import { Radio } from '@mui/material'
import { styled } from '@mui/system'
import theme from 'src/theme'

export const StyledRadio = styled(Radio)({
  color: theme.palette.primary.main,
  '&.Mui-checked': {
    color: theme.palette.primary.dark,
    '&.Mui-disabled': {
      color: theme.palette.primary.light
    }
  }
})
