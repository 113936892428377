import { Box, Link, Typography } from '@mui/material'

export const FormSuccess = () => {
  return (
    <Box mx="auto" width="70%" py={8}>
      <Typography variant="h4" color="primary.dark" mb={3}>
        Der Antrag wurde eingereicht
      </Typography>
      <Typography variant="body1">
        Vielen Dank für das Einreichen Ihrer Anzeige. Sie können den Entwurf in{' '}
        <Link href="/profil/meine-anzeigen" color="primary.dark">
          Meine Anzeigen
        </Link>{' '}
        ansehen und bearbeiten. Er wird von dem Administrator geprüft, sollte sich hieraus Fragen
        ergeben, werden Sie per E-Mail kontaktiert. Bis zum Zeitpunkt der Genehmigung können Sie
        Ihre eingereichte Anzeige bearbeiten und erneut einreichen. Sobald die Anzeige genehmigt
        wurde, wird sie auf der 3D-Karte erscheinen und kann dann nicht mehr bearbeitet werden.
        Sollten Sie Fragen haben, melden Sie sich bitte unter folgender E-Mail-Adresse: Weitere
        Informationen können Sie auf der{' '}
        <Link href="/info" color="primary.dark">
          Infoblatt page
        </Link>{' '}
        finden.
      </Typography>
    </Box>
  )
}
