import {
  Grid,
  Button,
  RadioGroup,
  FormControlLabel,
  MenuItem,
  Box,
  Typography,
  Select,
  InputLabel,
  OutlinedInput
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import { StyledTextField, StyledRadio } from '~components/input'
import { MarketingMethodsEnum, UtilitiesEnum } from '~services/listings'

import { stepTwoSchema, StepTwoSchemaType, VacancyDetailsTwoFormProps } from '.'

export const FormStepTwo = ({
  onSubmit,
  activeStep,
  handleBack,
  prefilledData
}: VacancyDetailsTwoFormProps) => {
  const theme = useTheme()

  const { control, handleSubmit } = useForm<StepTwoSchemaType>({
    resolver: zodResolver(stepTwoSchema),
    mode: 'onBlur'
  })

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Grid container width="50%" spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" color="primary.dark">
            Objektbeschreibung*
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="description"
            defaultValue={prefilledData.description}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                {...field}
                name={field.name}
                label="max. 200 Zeichen"
                variant="outlined"
                error={Boolean(error)}
                helperText={error?.message}
                inputRef={field.ref}
                size="small"
                multiline
                rows={6}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" color="primary.dark">
            Weitere Details (Alle sind fakultativ):
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" color="primary.main">
            Fläche - Erschließung
          </Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Controller
            name="utilities"
            defaultValue={prefilledData.utilities}
            control={control}
            render={({ field }) => (
              <>
                <InputLabel id="utilitiesMultiSelect">Versorgungseinrichtungen</InputLabel>
                <Select
                  {...field}
                  multiple
                  labelId="utilitiesMultiSelect"
                  label="Versorgungseinrichtungen"
                  size="small"
                  fullWidth
                  input={<OutlinedInput label="Versorgungseinrichtungen" />}
                >
                  <MenuItem value={UtilitiesEnum.water}>Wasser</MenuItem>
                  <MenuItem value={UtilitiesEnum.sewage}>Abwasser</MenuItem>
                  <MenuItem value={UtilitiesEnum.electricity}>Strom</MenuItem>
                  <MenuItem value={UtilitiesEnum.gas}>Gas</MenuItem>
                  <MenuItem value={UtilitiesEnum.phone}>Telefon</MenuItem>
                  <MenuItem value={UtilitiesEnum.cable}>Kabel</MenuItem>
                  <MenuItem value={UtilitiesEnum.fiber_internet}>Glasfaser</MenuItem>
                  <MenuItem value={UtilitiesEnum.district_heating}>Fernwärme</MenuItem>
                </Select>
              </>
            )}
          />
        </Grid>

        <Grid item xs={12} sx={{ display: 'flex', gap: 2 }}>
          <Grid item xs={12} md={4}>
            <Controller
              name="numRooms"
              defaultValue={prefilledData.numRooms}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <StyledTextField
                  {...field}
                  id="numRooms"
                  label="Zimmeranzahl"
                  variant="outlined"
                  error={Boolean(error)}
                  helperText={error?.message}
                  size="small"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name="floor"
              defaultValue={prefilledData.floor}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <StyledTextField
                  {...field}
                  id="floor"
                  label="Etage der Immobilien"
                  variant="outlined"
                  error={Boolean(error)}
                  helperText={error?.message}
                  size="small"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name="numFloors"
              defaultValue={prefilledData.numFloors}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <StyledTextField
                  {...field}
                  id="numFloors"
                  label="Gesamtzahl der Etagen"
                  variant="outlined"
                  error={Boolean(error)}
                  helperText={error?.message}
                  size="small"
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            name="constructionYear"
            defaultValue={prefilledData.constructionYear}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                {...field}
                id="constructionYear"
                label="Baujahr"
                variant="outlined"
                error={Boolean(error)}
                helperText={error?.message}
                size="small"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="renovationYear"
            defaultValue={prefilledData.renovationYear}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                {...field}
                id="renovationYear"
                label="Sanierungsjahr"
                variant="outlined"
                error={Boolean(error)}
                helperText={error?.message}
                size="small"
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" color="primary.dark">
            Steht das Gebäude unter Denkmalschutz?
          </Typography>
        </Grid>
        <Grid xs={12} item ml={1}>
          <Controller
            control={control}
            name="monumentStatus"
            defaultValue={prefilledData.monumentStatus}
            render={({ field }) => (
              <RadioGroup {...field} row>
                <FormControlLabel
                  value="true"
                  control={
                    <StyledRadio
                      sx={{
                        color: 'primary.main',
                        '&.Mui-checked': {
                          color: 'primary.dark'
                        }
                      }}
                    />
                  }
                  label="Ja"
                />
                <FormControlLabel
                  value=""
                  control={
                    <StyledRadio
                      sx={{
                        color: 'primary.main',
                        '&.Mui-checked': {
                          color: 'primary.dark'
                        }
                      }}
                    />
                  }
                  label="Nein"
                />
                <FormControlLabel
                  value="Nicht zutreffend"
                  control={
                    <StyledRadio
                      sx={{
                        color: 'primary.main',
                        '&.Mui-checked': {
                          color: 'primary.dark'
                        }
                      }}
                    />
                  }
                  label="Nicht zutreffend"
                />
              </RadioGroup>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" color="primary.dark">
            Verfügt die Immobilie über einen Keller?
          </Typography>
        </Grid>
        <Grid xs={12} item ml={1}>
          <Controller
            control={control}
            name="hasBasement"
            defaultValue={prefilledData.hasBasement}
            render={({ field }) => (
              <RadioGroup {...field} row>
                <FormControlLabel
                  value="true"
                  control={
                    <StyledRadio
                      sx={{
                        color: 'primary.main',
                        '&.Mui-checked': {
                          color: 'primary.dark'
                        }
                      }}
                    />
                  }
                  label="Ja"
                />
                <FormControlLabel
                  value=""
                  control={
                    <StyledRadio
                      sx={{
                        color: 'primary.main',
                        '&.Mui-checked': {
                          color: 'primary.dark'
                        }
                      }}
                    />
                  }
                  label="Nein"
                />
                <FormControlLabel
                  value="Nicht zutreffend"
                  control={
                    <StyledRadio
                      sx={{
                        color: 'primary.main',
                        '&.Mui-checked': {
                          color: 'primary.dark'
                        }
                      }}
                    />
                  }
                  label="Nicht zutreffend"
                />
              </RadioGroup>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" color="primary.dark">
            Verfügt die Immobilie über ein Dachboden?
          </Typography>
        </Grid>
        <Grid xs={12} item ml={1}>
          <Controller
            control={control}
            name="hasAttic"
            defaultValue={prefilledData.hasAttic}
            render={({ field }) => (
              <RadioGroup {...field} row>
                <FormControlLabel
                  value="true"
                  control={
                    <StyledRadio
                      sx={{
                        color: 'primary.main',
                        '&.Mui-checked': {
                          color: 'primary.dark'
                        }
                      }}
                    />
                  }
                  label="Ja"
                />
                <FormControlLabel
                  value=""
                  control={
                    <StyledRadio
                      sx={{
                        color: 'primary.main',
                        '&.Mui-checked': {
                          color: 'primary.dark'
                        }
                      }}
                    />
                  }
                  label="Nein"
                />
                <FormControlLabel
                  value="Nicht zutreffend"
                  control={
                    <StyledRadio
                      sx={{
                        color: 'primary.main',
                        '&.Mui-checked': {
                          color: 'primary.dark'
                        }
                      }}
                    />
                  }
                  label="Nicht zutreffend"
                />
              </RadioGroup>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" color="primary.dark">
            Ist die Immobilie möbliert?
          </Typography>
        </Grid>
        <Grid xs={12} item ml={1}>
          <Controller
            control={control}
            name="furnished"
            defaultValue={prefilledData.furnished}
            render={({ field }) => (
              <RadioGroup {...field} row>
                <FormControlLabel
                  value="true"
                  control={
                    <StyledRadio
                      sx={{
                        color: 'primary.main',
                        '&.Mui-checked': {
                          color: 'primary.dark'
                        }
                      }}
                    />
                  }
                  label="Ja"
                />
                <FormControlLabel
                  value=""
                  control={
                    <StyledRadio
                      sx={{
                        color: 'primary.main',
                        '&.Mui-checked': {
                          color: 'primary.dark'
                        }
                      }}
                    />
                  }
                  label="Nein"
                />
                <FormControlLabel
                  value="Nicht zutreffend"
                  control={
                    <StyledRadio
                      sx={{
                        color: 'primary.main',
                        '&.Mui-checked': {
                          color: 'primary.dark'
                        }
                      }}
                    />
                  }
                  label="Nicht zutreffend"
                />
              </RadioGroup>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" color="primary.dark">
            Marketing Details:
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Controller
              control={control}
              name="vacantSince"
              defaultValue={prefilledData.vacantSince}
              render={({ field, fieldState: { error } }) => (
                <>
                  <DatePicker
                    {...field}
                    onChange={value => {
                      return field.onChange(value ? dayjs(value) : dayjs(new Date()))
                    }}
                    label="Leer seit"
                    sx={{
                      width: '100%',
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: `1px solid ${
                            error ? theme.palette.error.main : theme.palette.primary.main
                          }`,
                          borderRadius: '6px'
                        },
                        '&:hover fieldset': {
                          borderColor: error ? theme.palette.error.main : theme.palette.primary.dark
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: error ? theme.palette.error.main : theme.palette.primary.dark
                        },
                        '& input::placeholder': {
                          color: 'blue',
                          opacity: 1
                        }
                      }
                    }}
                  />
                  <Typography color="error" mt="0.5rem" ml="0.9rem">
                    {error?.message}
                  </Typography>
                </>
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" color="primary.main">
            Bisherige Methoden
          </Typography>
        </Grid>
        <Grid item xs={12} md={7}>
          <Controller
            control={control}
            name="prevMarketingMethods"
            defaultValue={prefilledData.prevMarketingMethods}
            render={({ field, fieldState: { error } }) => (
              <>
                <StyledTextField {...field} select size="small" variant="outlined">
                  <MenuItem value={MarketingMethodsEnum.none}>Keine Aktivität</MenuItem>
                  <MenuItem value={MarketingMethodsEnum.agent_commissioned}>
                    Makler beauftragt
                  </MenuItem>
                  <MenuItem value={MarketingMethodsEnum.direct_tenant_contact}>
                    Direktansprache potenzieller Mieter
                  </MenuItem>
                  <MenuItem value={MarketingMethodsEnum.newspaper_add}>Zeitungsanzeige</MenuItem>
                  <MenuItem value={MarketingMethodsEnum.bank_contact}>
                    Kontakte über Banken
                  </MenuItem>
                  <MenuItem value={MarketingMethodsEnum.digital_media}>
                    Angebotsdarstellung über digital Medien
                  </MenuItem>
                  <MenuItem value={MarketingMethodsEnum.other}>Sonstiges</MenuItem>
                  <MenuItem value={MarketingMethodsEnum.not_applicable}>Nicht zutreffend</MenuItem>
                </StyledTextField>

                <Typography>{error?.message}</Typography>
              </>
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            control={control}
            name="mainRemarketingProblems"
            defaultValue={prefilledData.mainRemarketingProblems}
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                {...field}
                label="Haupthindernisse beim Versuch der Neuvermietung - max. 800 Zeichen"
                variant="outlined"
                error={Boolean(error)}
                helperText={error?.message}
                size="small"
                multiline
                rows={6}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="investmentDetails"
            defaultValue={prefilledData.investmentDetails}
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                {...field}
                label="Geplante Investitionen (Sanierungen am Objekt) - max. 800 Zeichen"
                variant="outlined"
                error={Boolean(error)}
                helperText={error?.message}
                size="small"
                multiline
                rows={6}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="reqImprovementsCommercialUse"
            defaultValue={prefilledData.reqImprovementsCommercialUse}
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                {...field}
                label="Nötige Unterstützungen zur Verbesserung - max. 800 Zeichen"
                variant="outlined"
                error={Boolean(error)}
                helperText={error?.message}
                size="small"
                multiline
                rows={6}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="neighbourCooperationDetails"
            defaultValue={prefilledData.neighbourCooperationDetails}
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                {...field}
                label="Denkbare Kooperationen mit Nachbarobjekten - max. 800 Zeichen"
                variant="outlined"
                error={Boolean(error)}
                helperText={error?.message}
                size="small"
                multiline
                rows={6}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Box mt="1.5rem" sx={{ display: 'flex', gap: 8 }}>
            <Button
              variant="contained"
              onClick={handleBack}
              sx={{ visibility: activeStep > 0 ? 'visible' : 'hidden', width: '13rem' }}
            >
              Zurück
            </Button>
            <Button variant="contained" type="submit" sx={{ width: '13rem' }}>
              {activeStep === 3 - 1 ? 'Absenden' : 'Nächster Schritt'}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
