import { useEffect } from 'react'
/* eslint-disable */
export function useDebounceEffect(
  fn: (...args: any[]) => void,
  waitTime: number,
  deps?: any[] | undefined
) {
  useEffect(() => {
    // This function will be executed after the specified waitTime
    const debouncedFn = () => {
      if (deps) fn(...deps)
    }

    // Set up a timeout to call the debounced function after waitTime
    const timeoutId = setTimeout(debouncedFn, waitTime)

    // This cleanup function will be called if the effect is cleaned up or re-run
    return () => {
      // Clear the previously set timeout if the effect is cleaned up before it fires
      clearTimeout(timeoutId)
    }
  }, deps) // The effect will re-run whenever the dependencies (deps) change
}
