import { Box, Container } from '@mui/material'

import { useAuthenticator } from '@aws-amplify/ui-react'
import { useOwner } from '~services/owner/useOwner'
import { UserForm } from '~components/userInfo'

export const EditProfile = () => {
  const { user } = useAuthenticator(context => [context.user])
  const { owner, mutate } = useOwner(user.username)

  return (
    <Container component="section" sx={{ display: 'flex' }}>
      <Box
        id="formWrapper"
        sx={{
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <UserForm userData={owner} mutate={mutate} />
      </Box>
    </Container>
  )
}
