import { createTheme } from '@mui/material/styles'

// A custom theme for this app
const theme = createTheme({
  typography: {
    fontFamily: ['Source Sans 3', 'sans-serif'].join(','),
    h1: { fontSize: '3rem', fontWeight: 700, lineHeight: '3.625rem' },
    h3: { fontSize: '1.25rem', fontWeight: 900, lineHeight: '1.75rem' },
    h4: { fontSize: '2rem', fontWeight: 700, lineHeight: '2.5rem' },
    h5: { fontSize: '1rem', fontWeight: 600, lineHeight: '1.75rem' },
    h6: { fontSize: '.75rem', fontWeight: 600, lineHeight: '.875rem' },
    body1: { fontSize: '.9rem', fontWeight: 400, lineHeight: '1rem' },
    body2: { fontSize: '.75rem', fontWeight: 800, lineHeight: '.875rem' },
    subtitle1: { fontSize: '.75rem', fontWeight: 400, lineHeight: '0.75rem' },
    subtitle2: { fontSize: '.6rem', fontWeight: 700, lineHeight: '0.75rem' }
  },
  palette: {
    primary: {
      light: '#A8A1A8',
      main: '#D6AF92',
      dark: '#9B4544',
      contrastText: '#FFF'
    },
    secondary: {
      light: '#6AB5B8',
      dark: '#737373',
      main: '#6AB5B8',
      contrastText: ' #A89FA6'
    },
    error: {
      light: '#FFC4C1',
      main: '#FF3B30'
    },
    neutral: {
      light: '#E5E5EA',
      main: '#D1D1D6',
      dark: '#F2F2F7'
    },
    warning: {
      main: '#FED385'
    },
    success: {
      main: '#ADA94E'
    }
  },
  breakpoints: {
    values: {
      xs: 480,
      sm: 640,
      md: 960,
      lg: 1280,
      xl: 1440
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          color: 'white',
          textTransform: 'none',
          paddingTop: '0.6rem',
          paddingBottom: '0.6rem'
        }
      },
      defaultProps: {
        disableElevation: true
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: 'transparent'
        }
      }
    }
  }
})

export default theme
