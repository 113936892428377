/**
 * MUI doesn't provide a simple way to replace the default error message for when string characters
 * are introduced into a TextField that has 'type="number"' set. Refining the field this way lets us
 * "translate" the error message and display it in the same way we deal with other error messages.
 * @param data - the field value that's being validated
 */
export const refinePosNumber = (data: string | undefined): data is string =>
  !data ? true : data ? !!Number(data) && Number(data) >= 0 : false

export const refinePosNumberMessage = 'Bitte eine positive Zahl eingeben.'
