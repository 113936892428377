import { z } from 'zod'
import dayjs, { Dayjs } from 'dayjs'

import {
  GeneralListingUseEnum,
  ListingTypesEnum,
  ListingUsesEnum,
  OwnerRelationshipsEnum
} from '~services/listings'
import { LeaseAgreementEnum } from '~components/listingForm/types'
import { refinePosNumber, refinePosNumberMessage } from '~utils/schemaUtils'

const stepOneSchema = z
  .object({
    title: z.string().nonempty('Dieses Feld muss ausgefüllt werden.'),
    address: z.object({
      streetname_number: z.string().nonempty('Dieses Feld muss ausgefüllt werden.'),
      address_id: z.number()
    }),
    transactionType: z.nativeEnum(ListingTypesEnum),
    leaseAgreement: z.nativeEnum(LeaseAgreementEnum).optional(),
    leaseStart: z.instanceof(dayjs as unknown as typeof Dayjs),
    leaseEnd: z.instanceof(dayjs as unknown as typeof Dayjs).optional(),
    price: z
      .string()
      .nonempty('Dieses Feld muss ausgefüllt werden.')
      .refine(refinePosNumber, refinePosNumberMessage),
    serviceCosts: z.string().refine(refinePosNumber, refinePosNumberMessage),
    retailSpaceSize: z
      .string()
      .nonempty('Dieses Feld muss ausgefüllt werden.')
      .refine(refinePosNumber, refinePosNumberMessage),
    propertySize: z.string().refine(refinePosNumber, refinePosNumberMessage),
    propertyType: z.nativeEnum(GeneralListingUseEnum),
    propertyStatus: z.nativeEnum(OwnerRelationshipsEnum),
    propertyUsage: z.nativeEnum(ListingUsesEnum)
  })
  .refine(data => data.address.streetname_number !== '', {
    message: 'Dieses Feld muss ausgefüllt werden.',
    path: ['address']
  })
  .refine(data => (data.transactionType === ListingTypesEnum.rent ? !!data.leaseStart : true), {
    message: 'Dieses Feld muss ausgefüllt werden.',
    path: ['leaseStart']
  })
  .refine(
    data =>
      data.transactionType === ListingTypesEnum.rent &&
      data.leaseAgreement === LeaseAgreementEnum.limited
        ? !!data.leaseEnd
        : true,
    {
      message: 'Dieses Feld muss ausgefüllt werden.',
      path: ['leaseEnd']
    }
  )
  .refine(
    data => {
      if (data.transactionType === ListingTypesEnum.buy) return true
      if (data.leaseAgreement === LeaseAgreementEnum.unlimited) return true

      return !data.leaseEnd || !data.leaseStart || data.leaseEnd.isAfter(data.leaseStart)
    },
    {
      message: 'Der Mietbeginn muss vor dem Mietende liegen.',
      path: ['leaseEnd']
    }
  )

type StepOneSchemaType = z.infer<typeof stepOneSchema>

export { stepOneSchema, type StepOneSchemaType }
