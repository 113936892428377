import { useState } from 'react'
import Image from 'mui-image'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { IconButton, Typography, Box } from '@mui/material'

import { CarouselProps } from '~components/card/types'

export const Carousel = ({ images }: CarouselProps) => {
  const [currImg, setCurrImg] = useState(0)

  const handleArrowChange = (direction: 'back' | 'forward') => () => {
    switch (direction) {
      case 'back': {
        if (currImg === 0) {
          setCurrImg(images.length - 1)
        } else {
          setCurrImg(currImg - 1)
        }
        break
      }
      case 'forward': {
        if (currImg === images.length - 1) {
          setCurrImg(0)
        } else {
          setCurrImg(currImg + 1)
        }
        break
      }
    }
  }

  return (
    <Box alignItems="center" justifyContent="center" width="100%" sx={{ position: 'relative' }}>
      {images.length > 1 && (
        <Box
          zIndex={3}
          sx={{
            position: 'absolute',
            left: -30,
            bottom: '40%'
          }}
        >
          <IconButton onClick={handleArrowChange('back')}>
            <ArrowBackIosIcon sx={{ fontSize: 30, color: 'neutral.light' }} />
          </IconButton>
        </Box>
      )}
      <Image
        src={images[currImg]}
        showLoading
        duration={0}
        style={{ maxHeight: '186px', maxWidth: '250px' }}
      />
      {images.length > 1 && (
        <Typography
          variant="body1"
          sx={{
            height: '24px',
            width: '48px',
            position: 'absolute',
            bottom: 10,
            left: 10,
            backgroundColor: 'white',
            padding: '.33rem',
            borderRadius: '.75rem',
            textAlign: 'center'
          }}
        >{`${currImg + 1} / ${images.length}`}</Typography>
      )}
      {images.length > 1 && (
        <Box
          zIndex={3}
          sx={{
            position: 'absolute',
            right: -35,
            bottom: '40%'
          }}
        >
          <IconButton onClick={handleArrowChange('forward')}>
            <ArrowForwardIosIcon sx={{ fontSize: 30, color: 'neutral.light' }} />
          </IconButton>
        </Box>
      )}
    </Box>
  )
}

export default Carousel
