export const mouseInstructions = `<table>
  <tbody>
    <tr>
      <td>
        <img
          src="/cesium/Widgets/Images/NavigationHelp/MouseLeft.svg"
          width="48"
          height="48"
        />
      </td>
      <td>
        <div class="cesium-navigation-help-pan">Panorama Ansicht</div>
        <div class="cesium-navigation-help-details">Linksklick + ziehen</div>
      </td>
    </tr>
    <tr>
      <td>
        <img
          src="/cesium/Widgets/Images/NavigationHelp/MouseRight.svg"
          width="48"
          height="48"
        />
      </td>
      <td>
        <div class="cesium-navigation-help-zoom">Zoom</div>
        <div class="cesium-navigation-help-details">Rechtsklick + ziehen, oder</div>
        <div class="cesium-navigation-help-details">Mausrad scrollen</div>
      </td>
    </tr>
    <tr>
      <td>
        <img
          src="/cesium/Widgets/Images/NavigationHelp/MouseMiddle.svg"
          width="48"
          height="48"
        />
      </td>
      <td>
        <div class="cesium-navigation-help-rotate">Ansicht drehen</div>
        <div class="cesium-navigation-help-details">
          Mittelklick + ziehen, oder
        </div>
        <div class="cesium-navigation-help-details">
          STRG + Links/Rechtsklick + ziehen
        </div>
      </td>
    </tr>
  </tbody>
</table>
`

export const touchInstructions = `<table>
  <tbody>
    <tr>
      <td>
        <img
          src="/cesium/Widgets/Images/NavigationHelp/TouchDrag.svg"
          width="70"
          height="48"
        />
      </td>
      <td>
        <div class="cesium-navigation-help-pan">Panorama Ansicht</div>
        <div class="cesium-navigation-help-details">Mit einem Finger ziehen</div>
      </td>
    </tr>
    <tr>
      <td>
        <img
          src="/cesium/Widgets/Images/NavigationHelp/TouchZoom.svg"
          width="70"
          height="48"
        />
      </td>
      <td>
        <div class="cesium-navigation-help-zoom">Zoom</div>
        <div class="cesium-navigation-help-details">Mit zwei Fingern hineinzoomen</div>
      </td>
    </tr>
    <tr>
      <td>
        <img
          src="/cesium/Widgets/Images/NavigationHelp/TouchTilt.svg"
          width="70"
          height="48"
        />
      </td>
      <td>
        <div class="cesium-navigation-help-rotate">Ansicht neigen</div>
        <div class="cesium-navigation-help-details">
          Mit zwei Fingern in eine Richtung ziehen
        </div>
      </td>
    </tr>
    <tr>
      <td>
        <img
          src="/cesium/Widgets/Images/NavigationHelp/TouchRotate.svg"
          width="70"
          height="48"
        />
      </td>
      <td>
        <div class="cesium-navigation-help-tilt">Ansicht drehen</div>
        <div class="cesium-navigation-help-details">
          Mit zwei Fingern entgegengesetzt ziehen
        </div>
      </td>
    </tr>
  </tbody>
</table>
`
