import { Box, Container, Grid, Typography, Paper, Button } from '@mui/material'
import { Link, useLocation, Outlet } from 'react-router-dom'
import { OwnerTypeEnum } from '~services/owner'
import { useOwner } from '~services/owner/useOwner'
import { useAuthenticator } from '@aws-amplify/ui-react'

export const MyProfile = () => {
  const { user } = useAuthenticator(context => [context.user])
  const { owner } = useOwner(user?.username)

  const { pathname } = useLocation()

  const isEdit: boolean = pathname.indexOf('bearbeiten') < 0

  return (
    <Container sx={{ display: 'flex', gap: 4, flexDirection: 'column' }}>
      <Typography variant="h4" color="primary.dark">
        Mein Profil
      </Typography>
      {isEdit ? (
        <Box sx={{ flexGrow: 1 }}>
          <Paper
            elevation={8}
            sx={{ maxWidth: '30rem', '&:not(:last-child)': { marginBottom: '1rem' } }}
          >
            <Grid container direction="row" padding="1rem">
              <Grid container item direction="column" xs={6} gap={1}>
                <Typography variant="h5">Persönliche Visitenkarte</Typography>

                <Typography variant="h6">{`${owner?.firstname} ${owner?.lastname}`}</Typography>
                <Typography marginBottom={owner?.company_name ? 0 : '1rem'}>
                  {owner?.owner_type === OwnerTypeEnum.commercial
                    ? 'Gewerbliche:r Anbieter:in'
                    : 'Private:r Anbieter:in'}
                </Typography>
                {owner?.owner_type === OwnerTypeEnum.commercial && owner?.company_name ? (
                  <Typography marginBottom="1rem">{owner?.company_name}</Typography>
                ) : null}
              </Grid>
            </Grid>
          </Paper>
          <Paper
            elevation={8}
            sx={{ maxWidth: '30rem', '&:not(:last-child)': { marginBottom: '1rem' } }}
          >
            <Grid container direction="row" padding="1rem">
              <Typography variant="h5">Kontaktangaben</Typography>

              <Grid item xs={12}>
                <Grid container my={1}>
                  <Grid item xs={12} lg={6}>
                    <Typography sx={{ wordBreak: 'break-word' }}>Adresse</Typography>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Typography>
                      {`${owner?.streetname} ${owner?.housenumber}${
                        owner?.houseletter ? ` ${owner?.houseletter}` : ''
                      }, ${owner?.zipcode} ${owner?.city}${
                        owner?.state ? `, ${owner?.state}` : ''
                      }, ${owner?.country}`}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container my={1}>
                  <Grid item xs={12} lg={6}>
                    <Typography>Email</Typography>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Typography>{owner?.email}</Typography>
                  </Grid>
                </Grid>
                <Grid container my={1}>
                  <Grid item xs={12} lg={6}>
                    <Typography>Kontaktnummer</Typography>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Typography>{owner?.phone}</Typography>
                  </Grid>
                </Grid>
                {owner?.company_name && (
                  <Grid container my={1}>
                    <Grid item xs={12} lg={6}>
                      <Typography>Unternehmen</Typography>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <Typography>{owner?.company_name}</Typography>
                    </Grid>
                  </Grid>
                )}
                {owner?.website && (
                  <Grid container my={1}>
                    <Grid item xs={12} lg={6}>
                      <Typography>Webseite</Typography>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <Typography>{owner?.website}</Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Paper>
          <Button variant="contained" component={Link} to={'bearbeiten'}>
            Bearbeiten
          </Button>
        </Box>
      ) : (
        <Outlet />
      )}
    </Container>
  )
}
