import { Navigate, useLocation } from 'react-router-dom'

import { UserRoleEnum } from '~services/owner'

import { RequireAdminProps } from '.'

export const RequireAdmin = ({ children, userRole }: RequireAdminProps) => {
  const location = useLocation()

  return userRole === UserRoleEnum.Admin ? (
    <>{children}</>
  ) : (
    <Navigate to="/profil/meine-anzeigen" state={{ from: location }} replace />
  )
}
