import { z } from 'zod'

import { EnergyClassEnum } from '~services/listings'
import { refinePosNumber, refinePosNumberMessage } from '~utils/schemaUtils'

// const MAX_FILE_SIZE = 10000000
// const ACCEPTED_IMAGE_TYPES = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp']

const stepThreeSchema = z
  .object({
    energyConsumption: z.string().optional().refine(refinePosNumber, refinePosNumberMessage),
    energyClass: z.nativeEnum(EnergyClassEnum).optional(),
    heatingType: z.string().optional(),
    heatingIssueYear: z.string().optional().refine(refinePosNumber, refinePosNumberMessage),
    innerAppearanceRating: z
      .union([z.literal(1), z.literal(2), z.literal(3), z.literal(4), z.literal(5)])
      .optional(),
    outerAppearanceRating: z
      .union([z.literal(1), z.literal(2), z.literal(3), z.literal(4), z.literal(5)])
      .optional(),
    internetMbits: z.string().optional().refine(refinePosNumber, refinePosNumberMessage),
    supplyTechnologyRating: z
      .union([z.literal(1), z.literal(2), z.literal(3), z.literal(4), z.literal(5)])
      .optional(),
    appearanceAdditionalInformation: z
      .string()
      .max(800, 'Die Beschreibung darf maximal 800 Zeichen lang sein.')
      .optional(),
    accessibilityRating: z.union([
      z.literal(1),
      z.literal(2),
      z.literal(3),
      z.literal(4),
      z.literal(5)
    ]),
    accessibilityExplanation: z
      .string()
      .max(800, 'Die Beschreibung darf maximal 800 Zeichen lang sein.')
      .optional(),
    other: z.string().max(800, 'Die Beschreibung darf maximal 800 Zeichen lang sein.').optional(),
    wishesConsultation: z.string(),
    images: z.any()
  })
  .refine(data => Number(data.heatingIssueYear) > 1700 || !data.heatingIssueYear, {
    message: 'Das Ausstellungsjahr muss nach 1700 liegen.',
    path: ['heatingIssueYear']
  })
  .refine(
    data =>
      Number(data.heatingIssueYear) <= Number(new Date().getFullYear()) || !data.heatingIssueYear,
    {
      message: 'Das Ausstellungsjahr kann nicht in der Zukunft liegen.',
      path: ['heatingIssueYear']
    }
  )

type StepThreeSchemaType = z.infer<typeof stepThreeSchema>

export { stepThreeSchema, type StepThreeSchemaType }
