import { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Authenticator, useAuthenticator, View, translations } from '@aws-amplify/ui-react'
import { I18n } from 'aws-amplify'
import { Box, Link, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import Image from 'mui-image'

import bigLogo from '~assets/images/logo-big-575x711.png'

I18n.putVocabularies(translations)
I18n.setLanguage('de')
I18n.putVocabulariesForLanguage('de', {
  'User does not exist.': 'Dieser Benutzer existiert nicht.',
  'Incorrect username or password.': 'Falscher Benutzername oder Passwort.',
  'Please fill in this field.': 'Bitte füllen Sie dieses Feld aus.',
  'Username/client id combination not found.':
    'Ein Benutzer mit diesem Namen wurde nicht gefunden.',
  'Network error': 'Netzwerkproblem'
})

export const Login = () => {
  const { route } = useAuthenticator(context => [context.route])
  const location = useLocation()
  const navigate = useNavigate()
  const from = location.state?.from?.pathname || '/'
  const theme = useTheme()

  useEffect(() => {
    if (route === 'authenticated') {
      navigate(from, { replace: true })
    }
  }, [route, navigate, from])

  const components = {
    ResetPassword: {
      Header() {
        return <></>
      }
    }
  }

  const formFields = {
    signIn: {
      username: {
        placeholder: 'Geben Sie Ihren Benutzernamen ein',
        isRequired: true,
        order: 1
      },
      password: {
        placeholder: 'Geben Sie Ihr Passwort ein',
        isRequired: true,
        order: 2
      }
    },
    resetPassword: {
      username: {
        label: 'Benutzername',
        placeholder: 'Geben Sie Ihren Benutzernamen ein',
        isRequired: true
      }
    }
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Image src={bigLogo} width="30rem" />

      <Box>
        <Typography variant="h4" color="primary.dark" sx={{ my: '1rem' }}>
          Einloggen
        </Typography>
        <Typography sx={{ my: '1.5rem', fontSize: '1rem', lineHeight: '1.2rem' }}>
          Melden Sie sich bei Ihrem Konto an, um Ihre Anzeigen zu verwalten.
          <br />
          Sie haben noch keinen Zugang? Dann können Sie sich{' '}
          <Link href="/registrieren" color={'primary.dark'}>
            hier kostenlos registrieren
          </Link>
          .
        </Typography>

        <View className="auth-wrapper">
          <style>{`
            [data-amplify-authenticator] {
              display: grid;
              justify-content: left;
            }

            [data-amplify-authenticator] [data-amplify-router] {
              border: none;
              box-shadow: none;
            }

            [data-amplify-authenticator] [data-amplify-form] {
              padding: 1rem 2rem;
            }

            .amplify-button[data-variation='primary'] {
              background-color: ${theme.palette.primary.main};
            }
            .amplify-button[data-variation='primary']:hover {
              background-color: ${theme.palette.success.main};
            }
            
            .amplify-button[data-variation='link'] {
              color: ${theme.palette.primary.main};
            }
            .amplify-button[data-variation='link']:hover {
              background-color: transparent;
              color: ${theme.palette.primary.dark};
            }
            
            .amplify-button[role='switch'] {
              border-color: ${theme.palette.primary.main};
            }
            .amplify-button[role='switch']:hover {
              background-color: transparent;
              color: ${theme.palette.primary.dark};
              border-color: ${theme.palette.primary.dark};
            }
            .amplify-button[role='switch']:active, .amplify-button[role='switch']:focus {
              background-color: transparent;
              color: ${theme.palette.primary.dark};
              border-color: ${theme.palette.primary.dark};
            }
            
            .amplify-label {
              color: ${theme.palette.primary.dark};
            }
            
            .amplify-input {
              border-color: ${theme.palette.primary.main};
            }
            .amplify-input:hover, .amplify-input:active, .amplify-input:focus {
              border-color: ${theme.palette.primary.dark};
            }
          `}</style>
          <Authenticator hideSignUp formFields={formFields} components={components} />
        </View>
      </Box>
    </Box>
  )
}
