import { ListItem, ListItemIcon, ListItemText, Box } from '@mui/material'

import { DetailRowProps } from '.'

export const DetailRow = ({ icon, information, link }: DetailRowProps) => {
  return (
    <Box my="5px">
      <ListItem disablePadding>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText>{information ? information : link ? link : 'N/A'}</ListItemText>
      </ListItem>
    </Box>
  )
}
