import useSWR from 'swr'

import { PaginatedResponse, Pagination } from '~types/ui'
import {
  ListingDetails,
  ListingsApprovalStatusEnum,
  ListingsStatusFilters
} from '~services/listings'
import { fetcher } from '~services/utils'

export const useListings = (
  statusFilters: ListingsStatusFilters = {},
  { limit, offset, previous }: Pagination = { limit: 6, offset: 0, previous: false }
) => {
  const params = new URLSearchParams({})

  let approval_status: ListingsApprovalStatusEnum | undefined | '' = statusFilters.approval_status
  if (approval_status === 'all') approval_status = ''

  if (approval_status) {
    params.append('approval_status', approval_status)
  }
  if (statusFilters.owner_id) {
    params.append('owner_id', String(statusFilters.owner_id))
  }
  if (statusFilters.address_id) {
    params.append('address_id', String(statusFilters.address_id))
  }

  const url =
    'listings/' +
    (limit ? `?limit=${limit}` : ``) +
    (offset ? `&offset=${offset}` : ``) +
    (previous ? `&previous=${previous}` : ``) +
    (params ? `&${params}` : ``)

  const { data, isLoading, error, mutate } = useSWR<PaginatedResponse<ListingDetails[]>>(
    url,
    (url: string) => fetcher(url, 'GET'),
    {
      revalidateOnMount: true,
      revalidateOnReconnect: true,
      revalidateIfStale: true,
      revalidateOnFocus: true
    }
  )

  return {
    listings: data,
    error,
    isLoading,
    mutate
  }
}
