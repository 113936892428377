import { TextField } from '@mui/material'
import { styled } from '@mui/system'
import theme from 'src/theme'

export const StyledTextField = styled(TextField)({
  width: '100%',
  '& .MuiOutlinedInput-root': {
    color: theme.palette.primary.dark,
    '& fieldset': { border: `1px solid ${theme.palette.primary.main}`, borderRadius: '6px' },
    '&:hover fieldset': { borderColor: theme.palette.primary.dark },
    '&.Mui-focused fieldset': { borderColor: theme.palette.primary.dark }
  }
})
