import { GeneralListingUseEnum, ListingTypesEnum } from '~services/listings'
import { VoucherTypesEnum } from '~services/vouchers'

export enum FilterTypesEnum {
  listing = 'Listing',
  voucher = 'Voucher'
}

export type FilterPanelInputValues = {
  min_usable_area?: string
  max_usable_area?: string
  min_price?: string
  max_price?: string
  listing_type?: ListingTypesEnum
  general_listing_use?: GeneralListingUseEnum
  gutscheine?: VoucherTypesEnum
}

export type FilterPanelProps = {
  handleFiltering: (query: FilterPanelInputValues) => void
  category: FilterTypesEnum
}
